<template>
  <v-container fluid>
    <v-card
      dark
      color="transparent"
      width="100%"
      class="mx-auto mt-10"
      elevation="0"
    >
      <v-card-title class="text-h5">Dashboard</v-card-title>
      
      <v-container fluid class="d-flex justify-center">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          clearable
          label="Search"
          hide-details
        ></v-text-field>
        <v-spacer />
      </v-container>

      <v-data-table
        :loading="get_data_load"
        :headers="headers"
        :items="ODP_data.Tubular"
        :search="search"
        class="elevation-1 custom-table"
        :footer-props="{'items-per-page-options':[5, 10, 50, 100, -1]}"
        :sort-by="'QTY'"
        :sort-desc="true"
      >

      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        { text: 'Order Number', value: 'Order_No' },
        { text: 'PO Number', value: 'PO' },
        { text: 'String No', value: 'String_No' },
        { text: 'Item No', value: 'Item_No' },
        { text: 'Equipment', value: 'Equipment' },
        { text: 'Quantity', value: 'QTY' },
        { text: 'Inspection', value: 'Inspection' },
        { text: 'Machining', value: 'Machining' },
        { text: 'Hardbanding', value: 'Hardbanding' },
        { text: 'Shipped', value: 'DT' }
      ],
      items: [], // This will be populated with your data
      ODP_data: { Tubular: [], BHA: [] }
    }
  },
  methods: {
    
  },
  computed: {
    ...mapGetters(['getUsername', 'getAsset', 'getFleet', 'get_data_load'])
  },
  mounted() {
    this.$initialDataLoad(this.$route.meta.backendRoutes, { username: this.$store.getters.getUsername })
  }
}
</script>

<style scoped>
::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #1C355E;
}

::v-deep .custom-table thead th {
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child {
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child {
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
</style>
