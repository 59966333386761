<script>
import { CONSTANTS } from '../constants.js';
import OrderBreakdown from './OrderBreakdown.vue';
import { barData, horisontalMultiBarData } from '../dataformatter';
import HorizontalBarCard from '../components/HorizontalBarCard.vue'
import ItemCard from '../components/ItemCard.vue'
import RecutRateTable from './RecutRateTable.vue';

export default {
  name: 'OCTGStatistics',
  props: {
    OCTGData: {
      type: Object,
    },
  },
  components: {
    RecutRateTable,
    ItemCard,
    OrderBreakdown,
    HorizontalBarCard
  },
  data() {
    return {
      orderDataTableHeaders: [
        {
          text: 'Item nr',
          value: 'Item_No',
          align: 'start',
        },
        {
          text: 'Order nr',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        {
          text: 'Inspection date',
          sortable: true,
          value: 'Inspection_Date'
        },
        { text: "Quantity", sortable: true, value: "Quantity" },
        { text: "Connection pin recut sum", sortable: true, value: "Machining_Pin_Sum_Recut" },
        { text: "Connection box recut sum", sortable: true, value: "Machining_Box_Sum_Recut" },
      ],
      machining_card_title: CONSTANTS.MACHINING_CARD_TITLE,
      machining_rates_header: [
        { text: "Connection", value: "connection", align: "start" },
        { text: "OK", value: "ok", align: "start" },
        { text: "Recut", value: "recut", align: "start" },
        { text: "SRF", value: "srf", align: "start" },
      ],
    }
  },
  methods: {
    barData, horisontalMultiBarData,
    handleRowClick(row) {
      this.$emit("rowClicked", row)
    }
  }
}
</script>


<template>
  <v-container>
    <v-row class="ma-2">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E" elevation="0">
          <ItemCard :itemObject="this.OCTGData.itemData" />
        </v-card>
      </v-col>
    </v-row>


    <v-row class="ma-2">
      <v-col cols="12">
        <v-card class="pa-2 cardWidth" min-height="800px" dark color="#1C355E">
          <v-card-title>{{ this.machining_card_title + " " +
            this.OCTGData.quantity_inspected + "x inspections excluding scrap"
          }}</v-card-title>
          <HorizontalBarCard
            :barData="horisontalMultiBarData(this.OCTGData.Machining_Data)"
            :height="'100%'">
          </HorizontalBarCard>

          <v-card-subtitle class="text-start">
            Recut rates
          </v-card-subtitle>
          <RecutRateTable
            :recutData="{ listOfRecutRates: this.OCTGData.machining_rates, recutRatesTableHeaders: this.machining_rates_header }">
          </RecutRateTable>
          
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-2">
      <v-col cols="12">
        <OrderBreakdown :orderData="{
          listOfOrderData: this.OCTGData.listOfOrderData,
          orderDataTableHeaders: orderDataTableHeaders
        }" @rowClicked="handleRowClick" :equipmentType="'OCTG'">

        </OrderBreakdown>

      </v-col>
    </v-row>
  </v-container>
</template>