<script>

export default {
  name: 'RecutRateTable',
  props: {
    recutData: {
      type: Object,
    },
  },
  mounted() {
  }
}
</script>

<template>
  <v-card>
    <v-data-table v-if="this.recutData !== null" hide-default-footer :headers="this.recutData.recutRatesTableHeaders"
      :items="this.recutData.listOfRecutRates"  header-class="text-start" cell-class="text-start" class="custom-table no-elevation">
      <template v-slot:item="{ item }">
        <tr  class="text-start">
          <td>{{ item.connection }}</td>
          <td>{{ item.ok }}%</td>
          <td v-if="'srf' in item">{{ item.srf }}%</td>
          <td>{{ item.recut }}%</td>
        </tr>
  
  
      </template>
  
    </v-data-table>

  </v-card>
</template>

<style scoped>
.text-start {
/* Align text to start / left */
  text-align: start;

}
</style>