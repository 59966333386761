<template>
  <div id="inspire">
    <v-navigation-drawer v-model="drawer" app temporary color="white">
      <v-layout justify-space-between column fill-height>
        <v-flex>
          <v-list>
            <v-list-item two-line>
              <v-list-item-avatar :color="icon_color"
                @click="changePasswordDialog = true" style="cursor: pointer">
                <v-icon class="clickable" dark>mdi-account-lock-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="getIsAdmin">
                  <router-link to="/admin"><v-icon
                      class="ma-1">mdi-account-edit</v-icon>
                  </router-link>
                  Viewing as:
                </v-list-item-title>
                <v-list-item-title class="text-h6">{{ getUsername
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ getAsset }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <router-link v-if="!isGuestUser" style="text-decoration: none"
              :to="{ name: test }">
              <v-list-item link>
                <v-list-item-action>
                  <v-icon :color="icon_color">mdi-home</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-left">Home</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <v-divider></v-divider>
            <!-- below comes menu without submenu -->
            <router-link v-for="rute in no_expand_routes_filtered"
              :key="rute.name" :value="false" :to="{ name: rute.route }"
              style="text-decoration: none">
              <v-list-item link>
                <v-list-item-action>
                  <v-icon :color="icon_color">{{ rute.iconname }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-left">{{ rute.name
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <!-- and now submenu -->
            <v-list-group v-for="rute in routes_filtered" :key="rute.name"
              :value="false">
              <template v-slot:activator>
                <v-list-item-action>
                  <v-icon :color="icon_color">{{ rute.iconname }}</v-icon>
                </v-list-item-action>
                <v-list-item-title class="text-left">{{
                  rute.name
                }}</v-list-item-title>
              </template>

              <v-list-item v-for="element in rute.sublist" :key="element.subname"
                :to="{ name: element.route }" style="text-decoration: none">
                <v-list-item-title class="text-left text-body-2 ml-5">{{
                  element.subname
                }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
    <v-app-bar app color="transparent" flat dark hide-on-scroll>
      <div class="d-flex justify-start" style="width: 300px">
        <v-app-bar-nav-icon v-if="getLoadingcomplete"
          @click.stop="drawer = !drawer"
          :disabled="isAssetPage"></v-app-bar-nav-icon>
      </div>

      <div class="header-logo">
        <v-img style="cursor: pointer" contain align="center" :src="image"
          @click="logoLink"></v-img>
      </div>

      <div style="width: 300px;" class="d-flex justify-end">
        <span v-if="getLoadingcomplete" v-show="this.$route.meta.hasBHAswitch"
          class="my-auto mr-4 text-button"
          :class="{ selected: currentPipeType === 'BHA' }"
          style="color: slategray">BHA</span>
        <v-switch inset class="mt-5" @change="changePipeType"
          v-model="switchModel" v-if="getLoadingcomplete"
          v-show="this.$route.meta.hasBHAswitch"></v-switch>
        <span v-if="getLoadingcomplete" v-show="this.$route.meta.hasBHAswitch"
          class="my-auto mr-3 ml-0 text-button"
          :class="{ selected: currentPipeType === 'Tubular' }"
          style="color: slategray">Tubular</span>
        <v-divider vertical v-if="getLoadingcomplete" class="mt-4" inset
          style="display: inline; height: 40px"
          v-show="this.$route.meta.hasBHAswitch" />

        <v-btn dark color="transparent" @click="logOut"
          :class="{ 'mt-4': this.$route.meta.hasBHAswitch }"
          v-show="getLoadingcomplete" elevation="0">Log out</v-btn>
      </div>

    </v-app-bar>
    <v-dialog v-model="changePasswordDialog" max-width="350px" persistent>
      <v-card dark>
        <v-toolbar color="#1C355E">
          <v-spacer />
          <v-toolbar-title>Change Password</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-form v-model="changePasswordForm" ref="changePasswordForm">
            <v-container>
              <v-row>
                <v-spacer />
                <v-btn icon @click="show_pw = !show_pw">
                  <v-icon v-if="show_pw">mdi-eye</v-icon>
                  <v-icon v-else>mdi-eye-off</v-icon>
                </v-btn>
              </v-row>
              <v-row>
                <v-text-field :type="show_pw ? 'text' : 'password'"
                  v-model="old_pw" label="Old Password" required
                  :rules="fieldRules.required"></v-text-field>
              </v-row>
              <v-row>
                <v-text-field hint="Minimum 6 characters"
                  :type="show_pw ? 'text' : 'password'" v-model="new_pw"
                  label="New Password" required
                  :rules="fieldRules.required.concat(fieldRules.newPassword).concat(fieldRules.diffPassword)"></v-text-field>
              </v-row>
              <v-row>
                <v-text-field hint="Minimum 6 characters"
                  :type="show_pw ? 'text' : 'password'" v-model="new_confirm_pw"
                  label="Confirm New Password" required
                  :rules="fieldRules.confirmPassword"></v-text-field>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeNewPasswordDialog" text color="error" plain
            :disabled="confirmLoading">Cancel</v-btn>
          <v-btn @click="changePassword" :disabled="!checkPw" text color="primary"
            plain :loading="confirmLoading">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar :color="changeColor" v-model="changeStatus" timeout="5000" dark>
      <v-row justify="center">
        <v-icon class="mr-3" v-if="changeColor === 'success'">mdi-check</v-icon>
        <v-icon class="mr-3" v-else>mdi-alert-circle</v-icon>
        {{ changeText }}
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import firebase from 'firebase'
import { mapActions, mapGetters } from 'vuex'
import image from '../assets/LOGO_Custom.png'
export default {
  name: 'navbar',
  data() {
    return {
      icon_color: '#1C355E',
      img_src: require('@/assets/navbar_logo.png'),
      drawer: false,
      test: 'Home',
      image: image,
      switchModel: false,
      changePasswordForm: false,
      confirmLoading: false,
      old_pw: '',
      show_pw: false,
      new_confirm_pw: '',
      changeText: '',
      changeStatus: false,
      changeColor: '',
      new_pw: '',
      fieldRules: {
        required: [(v) => !!v || 'This field is required'],
        newPassword: [(v) => (v && v.length >= 6) || 'New password must be minimum 6 characters'],
        diffPassword: [(v) => (v !== this.old_pw) || 'New password cannot be same as old password'],
        confirmPassword: [(v) => (v === this.new_pw) || 'New passwords do not match']
      },
      changePasswordDialog: false,
      currentPipeType: '',
      no_expand_routes: [
        {
          route: 'dashboard',
          iconname: 'dashboard',
          name: 'Dashboard'
        },
        {
          route: 'neworder',
          iconname: 'check_box',
          name: 'Order Confirmation'
        },
        {
          route: 'preinvoice',
          iconname: 'description',
          name: 'Pre Invoice'
        },
        {
          route: 'delivery',
          iconname: 'local_shipping',
          name: 'Shipping'
        }
      ],
      routes: [
        {
          route: 'insp',
          iconname: 'zoom_in',
          name: 'Inspection',
          sublist: [
            {
              subname: 'Drill Pipe',
              icon: '',
              route: 'dp',
            },
            {
              subname: 'Drill Collar',
              icon: '',
              route: 'dcollar',
            },
            {
              subname: 'OCTG',
              icon: '',
              route: 'octg',
            },
            {
              subname: 'Heavy Weight',
              icon: '',
              route: 'heavy',
            },
            {
              subname: 'BHA',
              icon: '',
              route: 'inspectionBHA',
            }
          ]
        },
        {
          route: 'inventory',
          iconname: 'view_module',
          name: 'Inventory',
          sublist: [
            {
              subname: 'Inventory Overview',
              icon: '',
              route: 'inventory'
            },
            {
              subname: 'Received',
              icon: '',
              route: 'received'
            }
          ]
        },
        {
          iconname: 'report',
          name: 'QHSE',
          sublist: [
            {
              subname: 'Non Conformance',
              icon: '',
              route: 'NCR'
            },

            // {
            //   subname: 'Certificates',
            //   icon: '',
            //   route: 'certificates'
            // }
          ]
        },
        {
          name: 'Statistics',
          iconname: 'mdi-microsoft-excel',
          sublist: [
            {
              subname: "Lead time",
              icon: "",
              route: "statistics",
            },
            {
              subname: "Inspection history",
              icon: "",
              route: "insptest"
            },
            {
              subname: "Lifecycle management",
              icon: "",
              route: "item-statistics"
            },
          ]
        },

      ]
    }
  },
  methods: {
    ...mapActions(['logOut', 'setPipeType', 'logOut', 'getEmail']),
    ...mapGetters(['getPipeType', 'getUsername']),
    changePipeType: function () {
      this.setPipeType()
      this.currentPipeType = this.$store.getters.getPipeType
    },
    getroute: function () {
      return this.$route.meta.displayname
    },
    changePassword() {
      this.confirmLoading = true
      var credentials = firebase.auth.EmailAuthProvider.credential(this.getEmail, this.old_pw)
      firebase.auth().currentUser.reauthenticateWithCredential(credentials)
        .then(() => {
          firebase.auth().currentUser.updatePassword(this.new_confirm_pw)
            .then(() => {
              this.changeColor = 'success'
              this.changeText = 'Successfully changed password'
              this.changeStatus = true
            })
            .catch((err) => {
              this.changeColor = 'error'
              this.changeText = 'Error: Password could not be changed'
              this.changeStatus = true
            })
            .finally(() => {
              this.confirmLoading = false
              this.closeNewPasswordDialog()
            })
        })
        .catch(() => {
          this.changeColor = 'error'
          this.changeText = 'Error: Check your old password again'
          this.changeStatus = true
          this.confirmLoading = false
        })
    },
    closeNewPasswordDialog() {
      this.old_pw = ''
      this.new_pw = ''
      this.new_confirm_pw = ''
      this.$refs.changePasswordForm.resetValidation()
      this.changePasswordDialog = false
    },
    logoLink() {
      if (this.$route.path !== '/login') {
        this.$router.push('/home')
      }
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getAsset', 'getIsAdmin', 'getEmail', 'getLoadingcomplete', 'getRouteslist']),
    checkPw() {
      return this.changePasswordForm && (this.new_pw === this.new_confirm_pw)
    },
    isAssetPage() {
      return this.$route.path === '/asset' || (this.getUsername === 'Ocean IMR AS' && this.$route.path === '/admin')
    },
    accessList() {
      return this.getRouteslist
    },
    isGuestUser() {
      return this.accessList.length > 0;
    },
    no_expand_routes_filtered () {
      var preinvoice_blacklist = ["ttys@odfjelltechnology.com", "lros@odfjelltechnology.com", "kjth@odfjelltechnology.com", "fsaa@odfjelltechnology.com"]
      var dashboard_whitelist = ["Independent Oil Tools AS"]
      if (this.isGuestUser) {
        return []
      } 
      if (preinvoice_blacklist.includes(this.getEmail)) {
        var newArray = this.no_expand_routes.filter(function (el) {
        return el.route !== "preinvoice"
        });
        return newArray
      }
      if (!dashboard_whitelist.includes(this.getUsername)) {
        var newArray = this.no_expand_routes.filter(function (el) {
        return el.route !== "dashboard"
        });
        return newArray
      }
      else {
        return this.no_expand_routes
      }
    },
    routes_filtered() {
      if (this.isGuestUser) {
        let accessRoutes = [{
          route: 'insp',
          iconname: 'zoom_in',
          name: 'Inspection',
          sublist: []
        }]
        for (const idx in this.routes[0].sublist) {
          if (this.accessList.includes(this.routes[0].sublist[idx].route)) accessRoutes[0].sublist.push(this.routes[0].sublist[idx])
        }
        if (this.accessList.includes('NCR')) accessRoutes.push(
          {
            iconname: 'report',
            name: 'QHSE',
            sublist: [
              {
                subname: 'Non Conformance',
                icon: '',
                route: 'NCR'
              }]
          })
        return accessRoutes
      }
      else {
        return this.routes
      }
    }
  },
  mounted() {
    this.currentPipeType = this.$store.getters.getPipeType
    if (this.currentPipeType === 'Tubular') {
      this.switchModel = true
    }
  }
}
</script>

<style lang="css" scoped>
.header-logo {
  justify-content: center;
  display: block;
  margin: 0 auto;
  width: 200px;
}

.header-title {
  text-align: left;
  margin-top: 8px;
  margin-left: 9px;
}

::v-deep .theme--dark.v-input--switch .v-input--switch__track {
  color: white !important;
}

::v-deep .theme--dark.v-input--switch .v-input--switch__thumb {
  color: white !important;
}

.selected {
  color: white !important;
}
</style>
